import { ConsentService } from '../../../core/services/consent.service';
import { HttpResponse } from '@angular/common/http';
import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import {
  LoadingEntity,
  ModalService,
  PageChangeType,
  PageData,
  RbCssTimer,
  Sort
} from '@inst-iot/bosch-angular-ui-components';
import { isEmpty, toNumber } from 'lodash-es';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, map, take } from 'rxjs/operators';
import { Constants } from '../../../../constants';
import { PaginationSettingsService } from '../../../shared/pagination-bar/pagination-settings.service';
import { listDeleteAnimation } from '../../../shared/animations/list-delete.animation';
import { User } from '../models/user.model';
import { UserAddService } from '../services/user-add.service';
import { UserApiService } from '../services/user-api.service';
import { UserEditComponent } from '../user-edit/user-edit.component';
import { translate } from '../../../shared/translation-util';
import { getSortFunction, sortRbTableData } from '../../../shared/sort-utils';
import { ProjectsService } from '../../../shared-projects/services/projects.service';
import { SearchInput } from '../../../shared-modules/data-selection/data-info-util';
import { RoleStoreService } from '../../role/services/role-store.service';
import { Role } from '../../role/models/role.model';
import { getUserListViewFilters } from '../../../shared-modules/query-condition-input/utils/filter-definitions';
import { SimpleSearchInputService } from '../../../shared-modules/simple-search/simple-search-input/services/simple-search-input.service';
import { SimpleSearchInputFilterService } from '../../../shared-modules/simple-search/simple-search-input/services/simple-search-input-filter.service';
import { FilterValues } from '../../../shared-modules/dynamic-filter/dynamic-filter.model';

@Component({
  selector: 'users-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  animations: [listDeleteAnimation],
  providers: [
    {
      provide: SimpleSearchInputService,
      useClass: SimpleSearchInputFilterService
    },
    PaginationSettingsService.withKey('users')
  ]
})
export class UserListComponent implements OnInit, OnDestroy {
  @Input() hasPendingUsers: boolean;
  userListLoader = new LoadingEntity<User[]>();
  userDeleteLoader = new LoadingEntity<null>();
  rolesLoader = new LoadingEntity<Role[]>();
  userList: User[] = [];
  userListCache: Record<string, { pageData: PageData; users: User[] }> = {};
  pageData: PageData = new PageData();
  sort: Sort;
  allEmails: string;
  rowItemChange$: Subject<RbCssTimer> = new Subject();

  rolesList: Role[];
  filterValues: Record<string, any> = {};
  searchInput: SearchInput = {
    filters: [],
    paths: [],
    type: 'filter'
  };

  readonly routing = Constants.routing;

  private _userErrorId: string;
  private _userDeleteId: string;
  private subscriptions = new Subscription();

  constructor(
    private userApiService: UserApiService,
    private userAddService: UserAddService,
    private modalService: ModalService,
    private injector: Injector,
    private activatedRoute: ActivatedRoute,
    private paginationSettings: PaginationSettingsService,
    private projectsService: ProjectsService,
    private rolesService: RoleStoreService,
    public consentService: ConsentService
  ) {
    if (!paginationSettings.loaded) {
      paginationSettings.limit = 10;
    }
  }

  ngOnInit() {
    this.subscribeForApiUsers();
    this.subscribeForFilterData();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.userListLoader.complete();
    this.rolesLoader.complete();
  }

  get userErrorId(): string {
    return this._userErrorId;
  }

  get userDeleteId(): string {
    return this._userDeleteId;
  }

  get storageKey() {
    return 'user-list-view_' + this.projectsService.projectName;
  }

  subscribeForApiUsers(): void {
    this.subscriptions.add(
      this.userAddService.onApiUserCreated$.subscribe(() => {
        this.getUserList(this.pageData.number, 'refresh');
      })
    );
  }

  subscribeForFilterData(): void {
    this.rolesLoader.run(this.rolesService.roles$).subscribe((roles) => {
      this.rolesList = roles;
      this.setFilterDefinitions();
    });
  }

  setPageData(pageNumber: number, response: HttpResponse<User[]>) {
    const totalCount = toNumber(response.headers.get('x-total-count'));
    this.pageData = new PageData({
      number: pageNumber,
      totalElements: totalCount,
      numberOfElements: response.body.length,
      size: this.paginationSettings.limit
    });
  }

  createEditModal(): UserEditComponent {
    const ref = this.modalService.openComponent(UserEditComponent, {}, this.injector);
    return ref.instance;
  }

  getComposedCacheKey(pageNumber: number, pageSize: number): string {
    return `${pageNumber}-${pageSize}-${JSON.stringify(this.filterValues)}`;
  }

  loadUsersFromCache(pageNumber: number) {
    const cache =
      this.userListCache[this.getComposedCacheKey(pageNumber, this.paginationSettings.limit)];
    this.userList = cache.users;
    this.sortUserList(this.sort);
    this.pageData = new PageData(cache.pageData);
  }

  loadUsers(pageNumber: number) {
    const userListObs = this.userApiService
      .getUserList(pageNumber, this.paginationSettings.limit, this.filterValues, true)
      .pipe(
        debounceTime(500),
        map((response: HttpResponse<User[]>) => {
          this.setPageData(pageNumber, response);
          return response.body;
        })
      );

    this.userListLoader.run(userListObs).subscribe((userList: User[]) => {
      this.userListCache[this.getComposedCacheKey(pageNumber, this.paginationSettings.limit)] = {
        users: userList,
        pageData: this.pageData
      };
      this.userList = userList;
      this.sortUserList(this.sort);
    });
  }

  isUserListInCache(pageNumber: number) {
    return !!this.userListCache[
      this.getComposedCacheKey(pageNumber, this.paginationSettings.limit)
    ];
  }

  getUserList(pageNumber = 0, event?: PageChangeType): void {
    if (event === 'refresh') {
      this.userListCache = {};
    }

    if (this.isUserListInCache(pageNumber)) {
      this.loadUsersFromCache(pageNumber);
    } else {
      this.loadUsers(pageNumber);
    }
    this.allEmails = this.userList.map((user) => user.email).join(';');
  }

  editUser(user: User): void {
    const editComponent: UserEditComponent = this.createEditModal();
    editComponent.editUser = new User(user);
    editComponent.editDone.pipe(take(1)).subscribe((editedUser: User | null) => {
      if (editedUser) {
        this.userList = User.updateUserInList(editedUser, this.userList) as User[];
        this.rowItemChange$.next({ id: user.userId, cssClass: 'table-success' });
      }
      this.modalService.close();
    });
  }

  deleteUser(userId: string): void {
    this._userErrorId = null;
    this.userDeleteLoader.run(this.userApiService.deleteUser(userId)).subscribe({
      next: () => {
        this._userDeleteId = userId;
        this.getUserList(this.pageData.number);
      },
      error: () => {
        this._userErrorId = userId;
        this.rowItemChange$.next({
          id: userId,
          cssClass: 'table-danger',
          duration: 10000
        });
      }
    });
  }

  removeUserFromList(userId: string) {
    if (this.userDeleteId === userId) {
      this.userList = User.deleteUserById(userId, this.userList) as User[];
      this._userDeleteId = null;
    }
  }

  trackByFn(index: number, user: User) {
    if (user) {
      return user.id;
    }
    return index;
  }

  getProviderLabel(user: User) {
    if (user.isBoschIdUser) {
      return 'Bosch ID';
    }
    if (user.isSingleKeyId) {
      return 'SingleKey ID';
    }
    if (user.isBoschEmployee) {
      return translate('users.list.boschEmployee');
    }
    if (user.isSkidStage) {
      return 'Skid Stage';
    }
    return '';
  }

  isConsentAccepted(revision: number) {
    return revision === this.consentService.consentRevision;
  }

  setFilterValues(filterValues: FilterValues) {
    this.filterValues = {};
    if (filterValues.name?.length) {
      this.filterValues.name = filterValues.name[0];
    }
    if (filterValues.email?.length) {
      this.filterValues.email = filterValues.email[0];
    }
    if (filterValues.createdAt?.length) {
      this.filterValues.createdAtFrom = filterValues.createdAt[0][0];
      this.filterValues.createdAtTo = filterValues.createdAt[0][1];
    }
    if (filterValues.lastSeenAt?.length) {
      this.filterValues.lastSeenAtFrom = ignoreTimezone(filterValues.lastSeenAt[0][0]);
      this.filterValues.lastSeenAtTo = ignoreTimezone(filterValues.lastSeenAt[0][1]);
    }
    if (filterValues.consentStatus?.length) {
      this.filterValues.consentStatus = filterValues.consentStatus[0];
    }
    if (filterValues.roles) {
      this.filterValues.roles = filterValues.roles;
    }

    function ignoreTimezone(isoString: string) {
      const date = new Date(isoString);
      const timezoneOffset = date.getTimezoneOffset() * 60000;
      return new Date(date.getTime() - timezoneOffset).toISOString();
    }
  }

  sortUserList(sort: Sort) {
    if (sort) {
      if (sort.id === 'name') {
        // in case of technical users the user.name property is used for the description
        const getValue = (u: User) => (u.isTechnical ? u.internalName : u.name);
        this.userList.sort(getSortFunction(getValue, sort.direction));
      } else {
        this.userList = sortRbTableData(sort, this.userList);
      }
    }
    this.sort = sort;
  }

  private setFilterDefinitions() {
    const projectHasConsent = !!this.consentService.consentRevision;

    this.searchInput.filters = getUserListViewFilters(projectHasConsent, this.rolesList);
  }
}
