<rb-dialog
  [dialogTitle]="title"
  cancelButtonLabel="{{
    showUserCredentials ? ('close' | translate | ucfirst) : ('cancel' | translate)
  }}"
  [cancelButtonCloseOnClick]="false"
  [cancelButtonAction]="onCancel.bind(this)"
  confirmationButtonLabel="{{ showUserCredentials ? undefined : ('create' | translate | ucfirst) }}"
  [confirmationButtonAction]="onSubmit.bind(this)"
  [confirmationButtonCloseOnClick]="false"
  [disableConfirmationButton]="form.invalid"
>
  <rb-callout type="warning" *ngIf="isUserLimitReached">
    {{ 'warningTooManyUsersForFreePlan' | translate }}
  </rb-callout>

  <ng-container *ngIf="!showUserCredentials">
    <p>
      {{ 'users.addUser.createApiUserDescription' | translate }}
    </p>

    <p class="my-3">
      {{ 'users.addUser.createApiUserNameInformation' | translate }}
    </p>
  </ng-container>

  <rb-callout
    *ngIf="showSuccessDialogue && credentialsLoader.result"
    data-cy="userCredentialsApiUserSuccessMsg"
    type="warning"
  >
    {{ 'users.addUser.notePasswordNow' | translate }}
  </rb-callout>

  <form #form="ngForm">
    <div *ngIf="!user">
      <h5>
        {{ 'users.addUser.technicalUserName' | translate }}
      </h5>

      <div class="d-flex align-items-center user-details-input mb-2">
        <p class="mr-1 text-nowrap">
          {{ project.projectName + '- ' }}
        </p>

        <rb-form-input
          name="Name"
          [label]="'users.addUser.name' | translate"
          [minlength]="5"
          [maxlength]="40"
          (ngModelChange)="updateValue({ internalName: $event })"
          [placeholder]="'users.addUser.randomName' | translate"
          [pattern]="techNameRegEx"
          class="w-100"
          [(ngModel)]="technicalUser.internalName"
        >
          <ng-template rbFormValidationMessage="minlength">
            {{ 'validation.minLength' | translate: { length: 5 } }}
          </ng-template>

          <ng-template rbFormValidationMessage="maxlength">
            {{ 'validation.maxLength' | translate: { length: 40 } }}
          </ng-template>

          <ng-template rbFormValidationMessage="pattern">
            {{ 'validation.patternNumberSmallLetterUnderscore' | translate }}
          </ng-template>
        </rb-form-input>

        <p class="ml-1 text-nowrap">-api</p>
      </div>

      <div class="mb-2">
        <rb-form-textarea
          [label]="'users.addUser.description' | translate"
          name="description"
          [minlength]="1"
          [maxlength]="255"
          [pattern]="descriptionRegEx"
          (ngModelChange)="updateValue({ name: $event })"
          [(ngModel)]="technicalUser.name"
        >
          <ng-template rbFormValidationMessage="maxlength">
            {{ 'validation.maxLength' | translate: { length: 255 } }}
          </ng-template>

          <ng-template rbFormValidationMessage="minlength">
            {{ 'validation.minLength' | translate: { length: 1 } }}
          </ng-template>

          <ng-template rbFormValidationMessage="pattern">
            {{ 'validation.patternLineBreak' | translate }}
          </ng-template>
        </rb-form-textarea>
      </div>
    </div>

    <ng-container *ngIf="user && showUserCredentials">
      <div class="add-user-input">
        <rb-form-input
          name="Name"
          label="Name"
          minlength="5"
          readonly
          data-cy="userCredentialsApiUserName"
          [ngModel]="user.internalName"
        ></rb-form-input>
        <a
          class="rb-ic rb-ic-lg rb-ic-copy"
          href="#"
          title="{{ 'copyToClipboard' | translate }}"
          [rbPopover]="'copied' | translate"
          [position]="'top'"
          [closePopoverAfter]="closePopoverAfterMs"
          (click)="clipboardService.copyToClipboard(user.internalName)"
        ></a>
      </div>

      <div class="add-user-input">
        <rb-form-input
          name="Password"
          label="{{ 'password' | translate }}"
          readonly
          [ngModel]="user.password"
        ></rb-form-input>
        <a
          class="rb-ic rb-ic-lg rb-ic-copy"
          href="#"
          title="{{ 'copyToClipboard' | translate }}"
          [rbPopover]="'copied' | translate"
          [position]="'top'"
          [closePopoverAfter]="closePopoverAfterMs"
          (click)="clipboardService.copyToClipboard(user.password)"
        ></a>

        <div class="add-user-input">
          <rb-form-textarea
            [label]="'users.addUser.description' | translate"
            name="Description"
            readonly
            maxlength="255"
            [ngModel]="user.name === user.internalName ? '' : user.name"
          ></rb-form-textarea>

          <a
            class="rb-ic rb-ic-lg rb-ic-copy"
            href="#"
            title="{{ 'copyToClipboard' | translate }}"
            [rbPopover]="'copied' | translate"
            [position]="'top'"
            [closePopoverAfter]="closePopoverAfterMs"
            (click)="clipboardService.copyToClipboard(user.name)"
          ></a>
        </div>

        <div *ngIf="showUserCredentials">
          <a
            href="#"
            aria-label="Copy API-Credentials as JSON"
            title="{{ 'copyToClipboard' | translate }}"
            [rbPopover]="'copied' | translate"
            [position]="'top'"
            [closePopoverAfter]="closePopoverAfterMs"
            (click)="clipboardService.copyToClipboard(copyAsJSON(user))"
          >
            {{ 'users.addUser.copyAsJSON' | translate }}
          </a>
        </div>
      </div>
    </ng-container>

    <div *rbLoading="credentialsLoader"></div>
  </form>
</rb-dialog>
