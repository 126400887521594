<ng-container *ngIf="pendingUsers.length">
  <div class="d-flex justify-content-between align-items-center">
    <h3>
      <div *ngIf="userInviteEmailEnabled; else titleUserAccounts">
        {{ 'users.pending.title' | translate }}
      </div>
    </h3>
    <user-invite></user-invite>
  </div>

  <div class="table-responsive mb-5">
    <table
      [rbSort]="null"
      [rbSortDisableSortClear]="true"
      *rbTable="pendingUsersLoader; maxHeight: '55vh'"
      (rbSortChange)="pendingUsers = sortData($event, pendingUsers)"
      class="m-table"
    >
      <thead>
        <tr>
          <th rb-sort-header="email">E-Mail</th>
          <th rb-sort-header="expiresAt">{{ 'users.pending.tableExpiry' | translate }}</th>
          <th rb-sort-header="projectRoles">{{ 'users.pending.tableRole' | translate }}</th>
          <th>{{ 'admin.projectConfig.actions' | translate }}</th>
        </tr>
      </thead>

      <tbody>
        <ng-container *ngFor="let user of pendingUsers; trackBy: trackByFn">
          <tr
            [id]="user.id"
            [@fadeInOutAnimation]="inviteDeleteId === user.inviteId"
            (@fadeInOutAnimation.done)="removeInviteFromList(user.inviteId)"
            [rbCssTimer]="user.id"
            [rbCssTimerEvent]="rowItemChange$"
            [rbCssTimerSelector]="'td:last-child'"
            [rbCssTimerSelectorClass]="'table-ic'"
          >
            <td title="{{ user.email }}">
              <div [attr.data-cy]="'userPendingListMail-' + user.email" class="ellipsis">
                {{ user.email }}
              </div>
            </td>

            <td [class.text-danger]="isInviteExpired(user.expiresAt)">
              {{ user.expiresAt | timeAgo }}
            </td>

            <td [attr.data-cy]="'userPendingListRoles'">{{ user.projectRoles.join(', ') }}</td>

            <td class="no-wrap">
              <a
                href="#"
                aria-label="click to edit this user"
                class="d-inline-block ml-3 rb-ic rb-ic-lg rb-ic-edit"
                (click)="editUser(user)"
              ></a>
              <a
                href="#"
                aria-label="click to delete this user"
                class="d-inline-block ml-3 rb-ic rb-ic-lg rb-ic-delete"
                [confirmation]="'users.list.deleteUser' | translate: { name: user.displayName }"
                (confirmed)="deleteInvite(user.inviteId)"
              ></a>
            </td>
          </tr>

          <tr *ngIf="deleteInviteLoader.error && inviteErrorId === user.inviteId">
            <td colspan="4" class="text-left">
              <div class="w-100" *rbLoading="deleteInviteLoader"></div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</ng-container>

<ng-template #titleUserAccounts>
  {{ 'users.pending.titleUserAccounts' | translate }}
</ng-template>
