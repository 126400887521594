<rb-dialog dialogTitle="{{ 'users.editUser.title' | translate | ucfirst }}">
  <form #form="ngForm">
    <!-- form-elements -->
    <div *ngIf="user?.isTechnical">
      <h5>
        {{ 'users.addUser.technicalUserName' | translate }}
      </h5>

      <div class="d-flex align-items-baseline user-details-input mb-2">
        <p class="mr-1 text-nowrap">
          {{ projectsService.projectName + '- ' }}
        </p>

        <rb-form-input
          name="Name"
          [label]="'users.addUser.name' | translate"
          [minlength]="5"
          [maxlength]="40"
          required
          [pattern]="techNameRegEx"
          class="w-100"
          [(ngModel)]="editUser['internalName']"
        >
          <ng-template rbFormValidationMessage="minlength">
            {{ 'validation.minLength' | translate: { length: 5 } }}
          </ng-template>

          <ng-template rbFormValidationMessage="maxlength">
            {{ 'validation.maxLength' | translate: { length: 40 } }}
          </ng-template>

          <ng-template rbFormValidationMessage="required">
            {{ 'validation.nameRequired' | translate }}
          </ng-template>

          <ng-template rbFormValidationMessage="pattern">
            {{ 'validation.patternNumberSmallLetterUnderscore' | translate }}
          </ng-template>
        </rb-form-input>

        <p class="ml-1 text-nowrap">-api</p>
      </div>

      <div class="mb-2">
        <rb-form-textarea
          [label]="'users.addUser.description' | translate"
          name="description"
          [minlength]="1"
          [maxlength]="255"
          [pattern]="descriptionRegEx"
          [(ngModel)]="editUser['name']"
        >
          <ng-template rbFormValidationMessage="minlength">
            {{ 'validation.minLength' | translate: { length: 1 } }}
          </ng-template>

          <ng-template rbFormValidationMessage="maxlength">
            {{ 'validation.maxLength' | translate: { length: 255 } }}
          </ng-template>

          <ng-template rbFormValidationMessage="pattern">
            {{ 'validation.patternLineBreak' | translate }}
          </ng-template>
        </rb-form-textarea>
      </div>
    </div>

    <h4 *ngIf="!user?.isTechnical">
      {{ editUser['name'] }}
    </h4>
    <!-- ./form-elements -->

    <user-role-selector
      [userRoles]="editUser.projectRoles"
      (roleSelectionChange)="editUser.projectRoles = $event"
    ></user-role-selector>

    <div *rbLoading="userEditLoader"></div>

    <div class="d-flex pt-4" *ngIf="!user?.isTechnical">
      {{ 'users.list.editUser' | translate }}
    </div>

    <!-- modal-buttons -->
    <div class="d-flex pt-4">
      <span *ngIf="hasProjectAdminRole && isPasswordRegenerative">
        <button
          class="rb-btn rb-secondary"
          [confirmation]="'users.editUser.regeneratePasswordWarning' | translate"
          [confirmTitle]="'users.editUser.regeneratePasswordTitle' | translate"
          [confirmLabel]="'users.editUser.regeneratePassword' | translate"
          (confirmed)="openCredentialsModal()"
        >
          {{ 'users.editUser.regeneratePasswordTitle' | translate }}
        </button>
      </span>

      <span class="ml-auto">
        <button class="rb-btn rb-secondary" type="button" (click)="close()">
          {{ 'cancel' | translate }}
        </button>
        <button
          class="rb-btn rb-primary ml-2"
          type="submit"
          (click)="saveUser()"
          [disabled]="form.invalid"
        >
          {{ 'save' | translate }}
        </button>
      </span>
    </div>
    <!-- ./modal-buttons -->
  </form>
</rb-dialog>
<ng-template #showCredentials>
  <user-credentials
    *rbLoading="credentialsLoader.loading"
    [title]="'users.editUser.regeneratePasswordTitle' | translate"
    [showUserCredentials]="true"
    [showSuccessDialogue]="true"
    [user]="user"
    [credentialsLoader]="credentialsLoader"
    (cancelClicked)="closeCredentialsModal(showCredentials)"
  ></user-credentials>
</ng-template>
