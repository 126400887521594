<a class="ml-2">
  <span>
    <button
      class="rb-btn rb-secondary"
      data-cy="userAddCreateApiUser"
      (click)="openAndInitModal(createApiUserModal)"
    >
      {{ 'users.addUser.createApiUser' | translate }}
    </button>
  </span>
</a>
<ng-template #createApiUserModal>
  <user-credentials
    [title]="'users.addUser.createApiUser' | translate | ucfirst"
    [showUserCredentials]="showCreatedUserCredentials"
    [user]="newApiUser"
    [credentialsLoader]="loader"
    [showSuccessDialogue]="showSuccessDialogue$ | async"
    (cancelClicked)="closeModal(createApiUserModal)"
    (submitClicked)="createApiUser($event)"
  ></user-credentials>
</ng-template>

<ng-template #addUserLabel>
  {{ 'users.addUser.addUser' | translate }}
</ng-template>

<ng-template #addUserTitle>
  {{ 'users.addUser.addUser' | translate | ucfirst }}
</ng-template>
