import { Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'users-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserViewComponent {
  hasPendingUsers: boolean;
  @HostBinding('class') class = 'user-view';

  setPendingUsersStatus(status: boolean) {
    this.hasPendingUsers = status;
  }
}
