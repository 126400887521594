import { forkJoin } from 'rxjs';
import { Component, Input } from '@angular/core';
import { LoadingEntity } from '@inst-iot/bosch-angular-ui-components';
import { User } from '../models/user.model';
import {
  downloadOptions,
  DownloadParameter
} from '../../../shared-modules/data-inspection/download-dialog/csv-download-model';
import {
  generateCsvUsingOptions,
  json2CsvOptionsFromDownloadParameterOptions
} from '../../../shared/csv-utils';
import { runBlobDownload } from '../../../shared/download-utils';
import { UserApiService } from '../services/user-api.service';
import { DatePipe } from '../../../shared/pipes/date.pipe';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { FilterValues } from '../../../shared-modules/dynamic-filter/dynamic-filter.model';

@Component({
  selector: 'user-list-export',
  templateUrl: './user-list-export.component.html'
})
export class UserListExportComponent {
  @Input() filter: FilterValues = {};
  @Input() projectConsentRevision = 0;
  @Input() totalUsersCount: number;

  exportLoader = new LoadingEntity<User[]>();

  readonly csvDownloadOptions = {
    ...downloadOptions,
    charset: [{ value: 'UTF-8', label: 'UTF-8' }]
  };

  constructor(
    private userApiService: UserApiService,
    private datePipe: DatePipe,
    private translateService: TranslateService
  ) {}

  exportAndDownloadCsv(downloadParams: DownloadParameter) {
    const numberOfRequests = Math.ceil(this.totalUsersCount / 50);
    const requestsObsList = new Array(numberOfRequests)
      .fill(null)
      .map((_, i) => this.userApiService.getUserList(i, 50, this.filter, true));

    this.exportLoader
      .run(forkJoin(requestsObsList).pipe(map((res) => res.flatMap((httpRes) => httpRes.body))))
      .subscribe((res) => {
        const userListCsv = this.convertUserListToCSV(res, downloadParams);
        const blob = new Blob([userListCsv], { type: 'text/csv; charset=utf-8' });
        runBlobDownload(blob, `users-list.csv`);
      });
  }

  private getCsvColumnPresets(): Record<string, string>[] {
    return [
      {
        label: 'Name',
        value: 'name'
      },
      {
        label: 'Email',
        value: 'email'
      },
      {
        label: 'Created',
        value: 'createdAt'
      },
      {
        label: 'Last Seen',
        value: 'lastSeenAt'
      },
      ...(this.projectConsentRevision
        ? [
            {
              label: 'Consent',
              value: 'consent'
            }
          ]
        : []),
      {
        label: 'Roles',
        value: 'projectRoles'
      }
    ];
  }

  private convertUserListToCSV(users: User[], downloadParams: DownloadParameter) {
    const opts = json2CsvOptionsFromDownloadParameterOptions(downloadParams);
    opts.fields = this.getCsvColumnPresets();
    return generateCsvUsingOptions(opts, this.getCsvFileDisplayData(users));
  }

  private getCsvFileDisplayData(users: User[]) {
    const displayData = [];

    users.forEach((user) => {
      displayData.push({
        name: user.name,
        email: user.email,
        createdAt: user.createdAt,
        lastSeenAt: user.lastSeenAt,
        consent: this.projectConsentRevision ? this.getConsentDisplayName(user) : '',
        projectRoles: user.projectRoles.join(', ')
      });
    });

    return displayData;
  }

  private getConsentDisplayName(user: User): string {
    if (!user.technical) {
      if (user.consentRevision === this.projectConsentRevision) {
        return this.translateService.instant('users.list.consentAccepted', {
          version: user.consentRevision,
          date: this.datePipe.transform(user.consent.acceptanceDate, 'L')
        });
      } else {
        return this.translateService.instant('users.list.consentStatus.pending');
      }
    } else {
      return '';
    }
  }
}
