<a
  (downloadDialog)="exportAndDownloadCsv($event)"
  [downloadOptions]="csvDownloadOptions"
  class="rb-link"
  href="#"
>
  <span class="rb-ic rb-ic-storage-local"></span>
  <span class="vline-icons-text-middle" data-cy="usersListExport">
    {{ 'users.list.exportAsCsv' | translate }}
  </span>
</a>
