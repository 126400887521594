import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { LoadingEntity, ModalService } from '@inst-iot/bosch-angular-ui-components';
import { UserPending } from '../models/user-pending.model';
import { User, UserLike, UserRoleUpdate, UserSetting } from '../models/user.model';
import { UserApiService } from '../services/user-api.service';
import { ProjectRole } from '../../../core/services/models/project-role';
import { UserAuthService } from '../../../core/services/user-auth.service';
import { ProjectsService } from '../../../shared-projects/services/projects.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'user-edit',
  templateUrl: './user-edit.component.html'
})
export class UserEditComponent implements OnInit {
  @Output()
  editDone = new EventEmitter<UserLike>();

  userEditLoader = new LoadingEntity<UserSetting>();

  credentialsLoader = new LoadingEntity<User>();

  editUser: UserLike;

  hasProjectAdminRole: boolean;
  techNameRegEx = /^[a-z0-9_]+$/;
  descriptionRegEx = /^[^/\n]*$/;

  @ViewChild('showCredentials') credentialsViewer;

  get isPasswordRegenerative(): boolean {
    // Disallow Password Regeneration for API Users handled by ServiceBroker
    return this.editUser instanceof User && this.editUser.isTechnical && this.editUser.isRemovable;
  }

  get user() {
    return this.editUser instanceof User ? this.editUser : null;
  }

  get technicalName(): string {
    if (this.editUser instanceof User) {
      const lengthOfProjectName = this.projectsService.projectName.length;
      const indexOfApi = this.editUser.internalName.lastIndexOf('api');

      return this.editUser.internalName.substring(lengthOfProjectName + 1, indexOfApi - 1);
    }
    return null;
  }

  constructor(
    private userApiService: UserApiService,
    private userAuthService: UserAuthService,
    public projectsService: ProjectsService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.hasProjectAdminRole = this.userAuthService.hasProjectRole(
      ProjectRole.admin(this.projectsService.projectName)
    );
    this.updateDescriptionIfEmpty();
    this.editUser['internalName'] = this.technicalName;
  }

  updateDescriptionIfEmpty() {
    if (this.editUser['internalName'] === this.editUser['name']) {
      this.editUser['name'] = '';
    }
  }

  close() {
    this.editDone.emit(null);
    this.editDone.complete();
  }

  saveUser() {
    if (this.editUser instanceof User) {
      if (this.editUser.isTechnical) {
        this.updateUserAndRoles();
      } else {
        this.updateUser();
      }
    } else if (this.editUser instanceof UserPending) {
      this.updatePendingUser();
    }
  }

  openCredentialsModal() {
    this.modalService.open(this.credentialsViewer, { stacked: true });
    this.regenerateUserPassword();
  }

  closeCredentialsModal(reference: TemplateRef<any>) {
    this.modalService.close(reference);
  }

  regenerateUserPassword() {
    this.credentialsLoader
      .run(this.userApiService.regeneratePassword(this.editUser.id))
      .subscribe((user: User) => {
        this.editUser = user;
      });
  }

  private updateUser(): void {
    this.userEditLoader
      .run(this.userApiService.updateUserRoleMappings(this.editUser.id, this.editUser.projectRoles))
      .subscribe((updatedRoles: UserRoleUpdate) => {
        const updatedUser = new User({
          ...this.editUser,
          projectRoles: updatedRoles.projectRoles
        });
        this.editDone.emit(updatedUser);
      });
  }

  private updateUserAndRoles(): void {
    const userName = this.editUser['internalName']
      ? `${this.projectsService.projectName}-${this.editUser['internalName']}-api`
      : null;
    this.userEditLoader
      .run(
        combineLatest([
          this.userApiService.updateApiUser(
            this.editUser['name'],
            userName,
            this.editUser.projectRoles,
            this.editUser.id
          ),
          this.userApiService.updateUserRoleMappings(this.editUser.id, this.editUser.projectRoles)
        ])
      )
      .subscribe(([user, project]: User[]): void => {
        const updatedUser = new User({
          ...user,
          projectRoles: project.projectRoles
        });
        this.editDone.emit(updatedUser);
      });
  }

  private updatePendingUser(): void {
    this.userEditLoader
      .run(this.userApiService.updatePendingUserRoles(this.editUser.id, this.editUser.projectRoles))
      .subscribe((updatedUser: UserPending) => this.editDone.emit(updatedUser));
  }
}
