<div class="d-flex justify-content-between align-items-center">
  <h3>
    <ng-container *ngIf="hasPendingUsers">{{ 'users.list.title' | translate }}</ng-container>
  </h3>
  <div *ngIf="!hasPendingUsers" class="mb-3">
    <user-invite></user-invite>
  </div>
</div>

<simple-search
  *rbLoading="rolesLoader"
  [searchInput]="searchInput"
  [storageKey]="storageKey"
  [hideSimpleSearchEditor]="true"
  [emitOnInit]="true"
  (valueChange)="setFilterValues($event)"
  (queryChange)="getUserList()"
></simple-search>
<pagination-bar
  [pageData]="pageData"
  [showSettings]="true"
  [inputLimits]="[10, 20, 50]"
  [extraTpl]="paginationExtra"
  (action)="getUserList($event.number, $event.userAction)"
></pagination-bar>

<div class="table-responsive mb-5">
  <table
    class="m-table"
    [rbSort]="null"
    [rbSortDisableSortClear]="true"
    *rbTable="userListLoader; maxHeight: '55vh'"
    (rbSortChange)="sortUserList($event)"
  >
    <thead>
      <tr>
        <th rb-sort-header="name">{{ 'users.list.name' | translate }}</th>
        <th rb-sort-header="createdAt">{{ 'users.list.createdAt' | translate }}</th>
        <th style="white-space: nowrap" rb-sort-header="lastSeenAt">
          {{ 'users.list.lastSeenAt' | translate }}
        </th>
        <th rb-sort-header="consent" *ngIf="consentService.consentRevision">
          {{ 'users.list.consent' | translate }}
        </th>
        <th rb-sort-header="projectRoles">{{ 'users.list.tableRole' | translate }}</th>
        <th>{{ 'admin.projectConfig.actions' | translate }}</th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let user of userList; trackBy: trackByFn">
        <tr
          [id]="user.userId"
          [@fadeInOutAnimation]="userDeleteId === user.userId"
          (@fadeInOutAnimation.done)="removeUserFromList(user.userId)"
          [rbCssTimer]="user.userId"
          [rbCssTimerEvent]="rowItemChange$"
          [rbCssTimerSelector]="'td:last-child'"
          [rbCssTimerSelectorClass]="'table-ic'"
        >
          <td title="{{ user.email }}">
            <div
              *ngIf="user.isTechnical; else noTechnicalUser"
              class="ellipsis"
              [attr.data-cy]="'userListUserName-' + user.internalName"
            >
              <p class="mb-0">
                {{ user.internalName }}
              </p>

              <div *ngIf="user.name !== user.internalName" class="ellipsis d-block small">
                {{ user.name }}
              </div>
            </div>

            <ng-template #noTechnicalUser>
              <div class="ellipsis" [attr.data-cy]="'userListUserName-' + user.name">
                {{ user.name }}
              </div>
            </ng-template>

            <label *ngIf="user.technical" class="badge badge-secondary d-inline-block align-top">
              API
            </label>

            <label
              class="badge"
              [class.badge-primary]="user.isBoschIdUser || user.isSingleKeyId"
              [class.badge-adfs]="user.isBoschEmployee"
              [class.badge-skidstage]="user.isSkidStage"
            >
              {{ getProviderLabel(user) | translate }}
            </label>

            <div class="ellipsis d-block small" *ngIf="!user.isTechnical && user.isBoschEmployee">
              {{ user.email }}
            </div>
          </td>

          <td>{{ user.createdAt | date: 'L' }}</td>

          <td>
            <ng-container *ngIf="user.lastSeenAt">
              {{ user.lastSeenAt | date: 'L':true }}
            </ng-container>
          </td>

          <td *ngIf="consentService.consentRevision">
            <div
              *ngIf="!user.isTechnical"
              class="d-flex align-items-center"
              [ngClass]="{ 'bosch-light-gray-w75': user.isTechnical }"
            >
              <i
                class="rb-ic mr-1"
                [ngClass]="{
                  'rb-ic-alert-success-filled text-success': isConsentAccepted(
                    user.consentRevision
                  ),
                  'rb-ic-hourglass text-warning': !isConsentAccepted(user.consentRevision)
                }"
                aria-hidden="true"
              ></i>
              <span>
                {{
                  isConsentAccepted(user.consentRevision)
                    ? ('users.list.consentAccepted'
                      | translate
                        : {
                            version: user.consentRevision,
                            date: user.consent.acceptanceDate | date: 'L'
                          })
                    : ('users.list.consentStatus.pending' | translate)
                }}
              </span>
            </div>
          </td>

          <td>{{ user.projectRoles?.join(', ') }}</td>

          <td>
            <button
              *ngIf="user.isTechnical"
              class="rb-btn rb-link rb-tiny d-inline-block rb-ic rb-ic-lg rb-ic-edit"
              type="button"
              [confirmation]="'users.list.editTechnicalUser' | translate"
              (confirmed)="editUser(user)"
            ></button>

            <button
              *ngIf="!user.isTechnical"
              class="rb-btn rb-link rb-tiny d-inline-block rb-ic rb-ic-lg rb-ic-edit"
              type="button"
              (click)="editUser(user)"
            ></button>

            <button
              class="rb-btn rb-link rb-tiny d-inline-block rb-ic rb-ic-lg rb-ic-delete"
              type="button"
              [attr.data-cy]="'userListDeleteUser-' + user.name"
              [disabled]="!user.removable"
              [confirmation]="
                'users.list.deleteUser'
                  | translate: { name: user.isTechnical ? user.displayName : user.name }
              "
              (confirmed)="deleteUser(user.userId)"
            ></button>
          </td>
        </tr>

        <tr *ngIf="userDeleteLoader.error && userErrorId === user.userId">
          <td colspan="3" class="text-left">
            <div class="w-100" *rbLoading="userDeleteLoader"></div>
          </td>
        </tr>
      </ng-container>

      <tr *ngIf="userListLoader.loading">
        <td [attr.colspan]="consentService.consentRevision ? 5 : 4" class="text-center">
          <div class="rb-loading-wrapper">
            <rb-loading-spinner [size]="0.5"></rb-loading-spinner>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <rb-empty-table *ngIf="userListLoader.result && !userList.length">
    {{ 'users.list.noUserFound' | translate }}
  </rb-empty-table>
  <pagination-bar [pageData]="pageData" (action)="getUserList($event.number)"></pagination-bar>
</div>

<ng-template #paginationExtra>
  <span class="ml-auto">
    <user-list-export
      #userListExport
      [projectConsentRevision]="consentService.consentRevision"
      [filter]="filterValues"
      [totalUsersCount]="pageData.totalElements"
    ></user-list-export>

    <a class="ml-2" [href]="'mailto:' + allEmails">
      <span class="rb-ic rb-ic-mail"></span>
      <span class="vline-icons-text-middle">
        {{ 'users.list.mailAll' | translate }}
      </span>
    </a>
    <a class="ml-2 rb-link" [href]="routing.helpUserManagement" target="help">
      <span class="rb-ic rb-ic-question-frame"></span>
      <span class="vline-icons-text-middle">
        {{ 'help' | translate }}
      </span>
    </a>
    <users-add></users-add>
  </span>
  <div class="w-100" *ngIf="userListExport.exportLoader.loading">
    <ng-container *rbLoading="userListExport.exportLoader"></ng-container>
  </div>
</ng-template>
