import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { LoadingEntity, ModalService } from '@inst-iot/bosch-angular-ui-components';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ClipboardService } from '../../../shared/services/clipboard.service';
import { User, UserLike } from '../models/user.model';
import { UserAddService } from '../services/user-add.service';
import { UserApiService } from '../services/user-api.service';
import { ProjectsService } from '../../../shared-projects/services/projects.service';

@Component({
  selector: 'users-add',
  templateUrl: './user-add.component.html',
  providers: [ClipboardService]
})
export class UserAddComponent implements OnDestroy {
  get showCreatedUserCredentials(): boolean {
    return this._showCreatedUserCredentials;
  }

  loader = new LoadingEntity<UserLike | true>();
  newApiUser: User;
  showSuccessDialogue$: Observable<boolean>;

  private _showCreatedUserCredentials = false;

  @ViewChild('inviteUserModal') inviteModal;
  @ViewChild('createApiUserModal') apiUserModal;

  constructor(
    private userAddService: UserAddService,
    private userApiService: UserApiService,
    private projectService: ProjectsService,
    private modalService: ModalService
  ) {}

  ngOnDestroy(): void {
    this.loader.complete();
  }

  openAndInitModal(ref: TemplateRef<any>): void {
    this.loader.result = null;
    this.newApiUser = null;
    this._showCreatedUserCredentials = false;
    this.showSuccessDialogue$ = new BehaviorSubject<boolean>(false);
    this.modalService.open(ref);
  }

  closeModal(ref: TemplateRef<any>): void {
    this.modalService.close(ref);
  }

  createApiUser(userDetails: User): void {
    const userName = `${this.projectService.projectName}-${userDetails.internalName}-api`;

    const payload = {
      username: userDetails?.internalName?.length ? userName : null,
      displayName: userDetails?.name?.length ? userDetails.name : null
    };

    this.newApiUser = new User();

    this.loader
      .run(this.userApiService.createApiUser(payload.username, payload.displayName))
      .subscribe({
        next: (apiUser: User) => {
          this.newApiUser = apiUser;
          this.showSuccessDialogue$ = of(true);
          this.userAddService.notifyApiUserCreated(apiUser);
          this._showCreatedUserCredentials = true;
        },
        error: () => {
          this._showCreatedUserCredentials = false;
          this.showSuccessDialogue$ = of(false);
        }
      });
  }
}
