import { Component, TemplateRef, ViewChild } from '@angular/core';
import { LoadingEntity, ModalService } from '@inst-iot/bosch-angular-ui-components';
import { UserLike } from '../models/user.model';
import { UserPending } from '../models/user-pending.model';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { ProjectUrlPipe } from '../../../shared-projects/pipes/project-url.pipe';
import { Location } from '@angular/common';
import { map, take } from 'rxjs/operators';
import { UserApiService } from '../services/user-api.service';
import { ProjectsService } from '../../../shared-projects/services/projects.service';
import { UserAddService } from '../services/user-add.service';
import { translate } from '../../../shared/translation-util';

@Component({
  selector: 'user-invite',
  templateUrl: './user-invite.component.html'
})
export class UserInviteComponent {
  get userInviteEmailEnabled(): boolean {
    return this.projectService.isUserInviteMailEnabled;
  }
  get isUserLimitReached(): boolean {
    return this.userApiService.isUserLimitReached();
  }

  get sentEmail() {
    return this._sentEmail;
  }

  get projectBaseUrl(): string {
    return (
      window.location.origin + this.location.prepareExternalUrl(this.projectUrl.transform('/'))
    );
  }

  loader = new LoadingEntity<UserLike | true>();
  newUser: UserPending = new UserPending();
  showSuccessDialogue$: Observable<boolean>;

  private readonly durationToShowSuccessInSec = 5;
  private _sentEmail: string;

  @ViewChild('form') ngForm: UntypedFormGroup;

  constructor(
    private userApiService: UserApiService,
    private projectService: ProjectsService,
    private userAddService: UserAddService,
    private modalService: ModalService,
    private projectUrl: ProjectUrlPipe,
    private location: Location
  ) {}

  ngOnDestroy(): void {
    this.loader.complete();
  }

  openAndInitModal(ref: TemplateRef<any>): void {
    this.loader.result = null;
    this.initNewUser();
    this.showSuccessDialogue$ = new BehaviorSubject<boolean>(false);
    this.modalService.open(ref);
  }

  getDialogTitle(): string {
    if (this.userInviteEmailEnabled) {
      return translate('users.addUser.inviteUser');
    } else {
      return translate('users.addUser.addUser');
    }
  }

  sendInvite(): void {
    this.loader
      .run(this.userApiService.createUserInvite(this.newUser.email, this.newUser.projectRoles))
      .subscribe((inviteResult: UserPending | true) => {
        if (inviteResult instanceof UserPending) {
          inviteResult.email = this.newUser.email;
          this._sentEmail = this.newUser.email;
        } else {
          this._sentEmail = null;
        }
        this.startTimer();
        this.initNewUser(this.newUser.projectRoles);
        this.ngForm.reset();
        this.userAddService.notifyUserInvite(inviteResult);
      });
  }

  private startTimer(): void {
    const timer$ = timer(0, 1000);
    this.showSuccessDialogue$ = timer$.pipe(
      take(this.durationToShowSuccessInSec),
      map((time: number) => time + 1 !== this.durationToShowSuccessInSec)
    );
  }

  private initNewUser(projectRoles?: string[]): void {
    this.newUser = projectRoles?.length
      ? new UserPending({ projectRoles: [...projectRoles] })
      : new UserPending({ projectRoles: ['access'] });
  }
}
