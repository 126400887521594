import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoadingEntity } from '@inst-iot/bosch-angular-ui-components';
import { ProjectsService } from '../../../shared-projects/services/projects.service';
import { ClipboardService } from '../../../shared/services/clipboard.service';
import { User } from '../models/user.model';
import { UserApiService } from '../services/user-api.service';

@Component({
  selector: 'user-credentials',
  templateUrl: './user-credentials.component.html',
  styleUrls: ['./user-credentials.component.scss'],
  providers: [ClipboardService]
})
export class UserCredentialsComponent {
  @Input() credentialsLoader: LoadingEntity<any>;

  @Input() showUserCredentials = false;

  @Input() showSuccessDialogue = false;

  @Input() title;

  @Input() user: User = new User();

  @Output() submitClicked = new EventEmitter<any>();

  @Output() cancelClicked = new EventEmitter<any>();

  technicalUser: User = new User();

  techNameRegEx = /^[a-z0-9_]+$/;
  descriptionRegEx = /^[^/\n]*$/;

  closePopoverAfterMs = 1000;

  constructor(
    public clipboardService: ClipboardService,
    public project: ProjectsService,
    private userApiService: UserApiService
  ) {}

  copyAsJSON(newApiUser: User): string {
    return JSON.stringify(
      {
        name: newApiUser.internalName,
        password: newApiUser.password,
        description: newApiUser.name === newApiUser.internalName ? '' : newApiUser.name
      },
      null,
      2
    );
  }

  updateValue(value) {
    if (Object.prototype.hasOwnProperty.call(value, 'name')) {
      this.technicalUser.name = value;
    } else if (Object.prototype.hasOwnProperty.call(value, 'internalName')) {
      this.technicalUser.internalName = value;
    }
  }

  onSubmit() {
    this.submitClicked.emit(this.technicalUser);
  }

  onCancel() {
    this.cancelClicked.emit();
    this.showSuccessDialogue = false;
  }
  get isUserLimitReached(): boolean {
    return this.userApiService.isUserLimitReached();
  }
}
