<button
  class="add-user-btn d-block rb-btn rb-primary"
  data-cy="userAddInviteUser"
  (click)="openAndInitModal(inviteUserModal)"
>
  {{ getDialogTitle() | translate }}
</button>

<ng-template #inviteUserModal>
  <rb-dialog
    [dialogTitle]="getDialogTitle() | translate | ucfirst"
    confirmationButtonLabel="{{
      userInviteEmailEnabled
        ? ('users.addUser.invite' | translate)
        : ('users.addUser.add' | translate)
    }}"
    [confirmationButtonAction]="sendInvite.bind(this)"
    [disableConfirmationButton]="form.invalid || loader.loading"
    [confirmationButtonCloseOnClick]="false"
    cancelButtonLabel="{{ 'cancel' | translate }}"
  >
    <rb-callout type="warning" *ngIf="isUserLimitReached">
      {{ 'warningTooManyUsersForFreePlan' | translate }}
    </rb-callout>

    <form #form="ngForm">
      <!-- form-elements -->
      <rb-form-input
        name="E-MailAddress"
        class="required"
        email
        required
        label="E-Mail"
        data-cy="userAddInviteByMail"
        [disabled]="loader.loading"
        [autofocus]="true"
        [(ngModel)]="newUser.email"
      >
        <ng-template rbFormValidationMessage="email">
          {{ 'emailInvalid' | translate }}
        </ng-template>
      </rb-form-input>
      <!-- ./form-elements -->

      <user-role-selector
        [userRoles]="newUser.projectRoles"
        (roleSelectionChange)="newUser.projectRoles = $event"
      ></user-role-selector>

      <div *rbLoading="loader"></div>

      <div class="mt-3" *ngIf="showSuccessDialogue$ | async">
        <rb-callout [type]="'success'">
          <div *ngIf="sentEmail">
            <span data-cy="userAddInviteSuccessMsg" *ngIf="userInviteEmailEnabled">
              {{ 'users.addUser.inviteSuccess' | translate: { email: sentEmail } }}
            </span>
            <span data-cy="userAddInviteSuccessMsg" *ngIf="!userInviteEmailEnabled">
              {{ 'users.addUser.userAddedLoginRequired' | translate: { url: projectBaseUrl } }}
            </span>
          </div>
          <span *ngIf="!sentEmail">
            {{ 'users.addUser.userAddedSuccess' | translate }}
          </span>
        </rb-callout>
      </div>
    </form>
  </rb-dialog>
</ng-template>
