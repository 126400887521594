import { Component, EventEmitter, Injector, OnDestroy, OnInit, Output } from '@angular/core';
import { LoadingEntity, ModalService, RbCssTimer } from '@inst-iot/bosch-angular-ui-components';
import { Subject, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { listDeleteAnimation } from '../../../shared/animations/list-delete.animation';
import { UserPending } from '../models/user-pending.model';
import { User } from '../models/user.model';
import { UserAddService } from '../services/user-add.service';
import { UserApiService } from '../services/user-api.service';
import { UserEditComponent } from '../user-edit/user-edit.component';
import { sortRbTableData } from '../../../shared/sort-utils';
import { ProjectsService } from '../../../shared-projects/services/projects.service';
import moment from 'moment';

@Component({
  selector: 'users-pending-list',
  templateUrl: './user-pending-list.component.html',
  animations: [listDeleteAnimation]
})
export class UserPendingListComponent implements OnInit, OnDestroy {
  get inviteDeleteId(): string {
    return this._inviteDeleteId;
  }

  get inviteErrorId(): string {
    return this._inviteErrorId;
  }

  get userInviteEmailEnabled(): boolean {
    return this.projectService.isUserInviteMailEnabled;
  }

  pendingUsersLoader = new LoadingEntity<UserPending[]>();
  deleteInviteLoader = new LoadingEntity<null>();

  pendingUsers: UserPending[] = [];

  private _inviteErrorId: string;
  private _inviteDeleteId: string;
  private subscriptions = new Subscription();

  readonly sortData = sortRbTableData;
  rowItemChange$: Subject<RbCssTimer> = new Subject();

  @Output() pendingUsersStatusChange = new EventEmitter<boolean>();

  constructor(
    private userApiService: UserApiService,
    private userAddService: UserAddService,
    private projectService: ProjectsService,
    private modalService: ModalService,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.getPendingUsers();
    this.subscribeForInvites();
  }

  ngOnDestroy(): void {
    this.pendingUsersLoader.complete();
    this.deleteInviteLoader.complete();
    this.subscriptions.unsubscribe();
  }

  subscribeForInvites(): void {
    this.subscriptions.add(
      this.userAddService.onUserInvited$
        .pipe(filter((newInvite) => newInvite instanceof UserPending))
        .subscribe(() => {
          this.getPendingUsers();
        })
    );
  }

  getPendingUsers(): void {
    this.pendingUsersLoader
      .run(this.userApiService.getPendingUsersList())
      .subscribe((users: UserPending[]) => {
        this.pendingUsers = users;
        this.pendingUsersStatusChange.emit(users.length > 0);
      });
  }

  createEditModal(): UserEditComponent {
    const ref = this.modalService.openComponent(UserEditComponent, {}, this.injector);
    return ref.instance;
  }

  editUser(user: UserPending): void {
    const editComponent: UserEditComponent = this.createEditModal();
    editComponent.editUser = new UserPending(user);
    editComponent.editDone.pipe(take(1)).subscribe((editedUser: UserPending | null) => {
      if (editedUser) {
        this.pendingUsers = User.updateUserInList(editedUser, this.pendingUsers);
      }
      this.modalService.close();
      this.rowItemChange$.next({ id: user.id, cssClass: 'table-success' });
    });
  }

  deleteInvite(inviteId: string): void {
    this._inviteErrorId = null;
    this.deleteInviteLoader.run(this.userApiService.deleteInvite(inviteId)).subscribe({
      next: () => {
        this._inviteDeleteId = inviteId;
      },
      error: () => {
        this._inviteErrorId = inviteId;
        this.rowItemChange$.next({
          id: inviteId,
          cssClass: 'table-danger',
          duration: 10000
        });
      }
    });
  }

  removeInviteFromList(inviteId: string) {
    if (this.inviteDeleteId === inviteId) {
      this.pendingUsers = User.deleteUserById(inviteId, this.pendingUsers);
      this._inviteDeleteId = null;
      this.pendingUsersStatusChange.emit(this.pendingUsers.length > 0);
    }
  }

  isInviteExpired(isoDate: string) {
    return moment().toISOString() >= isoDate;
  }

  trackByFn(index: number, user: UserPending) {
    if (user) {
      return user.id;
    }
    return index;
  }
}
